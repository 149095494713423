import { post, get, download, excel } from '../utils/http'

// 获取等级奖学金管理流程
export function GetDJStep() {
  return get('py/SY_PY_DX_LCPZ/GetStep/')
}

// 提交等级奖学金流程配置
export function DJLCPZSave(data) {
  return post('py/SY_PY_DX_LCPZ/LCPZSave/', data)
}

// 保存批次设置
export function saveDXPCSZ(data) {
  return post('py/SY_PY_DX_PCSZ/Save/', data)
}

// 获取批次
export function GetDXPCPageList(data) {
  return get('py/SY_PY_DX_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getDXFormData(data) {
  return get('py/SY_PY_DX_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changeDXState(data) {
  return post('py/SY_PY_DX_PCSZ/SaveDQPC/', data)
}

// 学生单项积极分子当前批次
export function stuDXGetPC() {
  return get('py/SY_PY_DX_XSSQ/StuGetPC/')
}

// 学生获取申请分页数据
export function getDXStuApplyPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetStuApplyPageList/', data)
}

// 提交单项积极分子流程配置
export function DXSave(data) {
  return post('py/SY_PY_DX_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getDXSQFormData(data) {
  return get('py/SY_PY_DX_XSSQ/GetFormData/', data)
}

// 获取单项积极分子待审批列表
export function getDXDSPPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetDSPPageList/', data)
}

// 获取单项积极分子待审批列表
export function getDXDXYSPPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetDXYSPPageList/', data)
}

// 获取单项积极分子待审批列表
export function getDXDXGSPPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetDXGSPPageList/', data)
}

// 获取单项积极分子待审批列表
export function saveDXSP(data) {
  return post('py/SY_PY_DX_XSSQ/saveSP/', data)
}

// 获取单项积极分子待审批列表
export function saveDXXYSP(data) {
  return post('py/SY_PY_DX_XSSQ/saveXYPLSP/', data)
}
// 获取单项积极分子待审批列表
export function saveDXXGSP(data) {
  return post('py/SY_PY_DX_XSSQ/saveXGPLSP/', data)
}

// 获取单项积极分子PDF
export function getSQBPdf(data, filename) {
  return download('py/SY_PY_DX_XSSQ/GetSQBPdf/', data, filename)
}

// 获取单项积极分子PDF
export function getDXPdf(data, filename) {
  return download('py/SY_PY_DX_XSSQ/GetPdf/', data, filename)
}

// 获取单项积极分子PDF
export function getDXPdfUrl(data) {
  return post('py/SY_PY_DX_XSSQ/GetPdfUrl/', data)
}

// 获取查询列表
export function getDXCXPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetCXPageList/', data)
}

// 获取导出数据
export function exportDXSearchExcel(data) {
  return excel('py/SY_PY_DX_XSSQ/ExportExcel/', data)
}

// 获取导出数据
export function exportDXXYSearchExcel(data) {
  return excel('py/SY_PY_DX_XSSQ/ExportXYExcel/', data)
}

// 获取导出数据
export function exportDXXGSearchExcel(data) {
  return excel('py/SY_PY_DX_XSSQ/ExportXGExcel/', data)
}

// 获取三好学生管理流程
export function GetSHXSStep() {
  return get('py/SY_PY_SHXS_LCPZ/GetStep/')
}

// 提交三好学生流程配置
export function SHXSLCPZSave(data) {
  return post('py/SY_PY_SHXS_LCPZ/LCPZSave/', data)
}

// 保存批次设置
export function saveSHXSPCSZ(data) {
  return post('py/SY_PY_SHXS_PCSZ/Save/', data)
}

// 获取批次
export function GetSHXSPCPageList(data) {
  return get('py/SY_PY_SHXS_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getSHXSFormData(data) {
  return get('py/SY_PY_SHXS_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changeSHXSState(data) {
  return post('py/SY_PY_SHXS_PCSZ/SaveDQPC/', data)
}

// 学生当前批次
export function stuSHXSGetPC() {
  return get('py/SY_PY_SHXS_XSSQ/StuGetPC/')
}

// 学生获取申请分页数据
export function getSHXSStuApplyPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetStuApplyPageList/', data)
}

// 提交单项积极分子流程配置
export function SHXSSave(data) {
  return post('py/SY_PY_SHXS_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getSHXSSQFormData(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getSHXSDSPPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetDSPPageList/', data)
}

// 获取待学院审批列表
export function getSHXSDXYSPPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetDXYSPPageList/', data)
}

// 获取待学工审批列表
export function getSHXSDXGSPPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetDXGSPPageList/', data)
}

// 提交审批
export function saveSHXSSP(data) {
  return post('py/SY_PY_SHXS_XSSQ/saveSP/', data)
}

// 提交审批
export function saveSHXSXYSP(data) {
  return post('py/SY_PY_SHXS_XSSQ/saveXYPLSP/', data)
}

// 提交审批
export function saveSHXSXGSP(data) {
  return post('py/SY_PY_SHXS_XSSQ/saveXGPLSP/', data)
}

// 获取PDF
export function getSHXSSQBPdf(data, filename) {
  return download('py/SY_PY_SHXS_XSSQ/GetSQBPdf/', data, filename)
}

// 获取PDF
export function getSHXSPdf(data, filename) {
  return download('py/SY_PY_SHXS_XSSQ/GetPdf/', data, filename)
}

// 获取PDF
export function getSHXSPdfUrl(data) {
  return post('py/SY_PY_SHXS_XSSQ/GetPdfUrl/', data)
}

// 获取查询列表
export function getSHXSCXPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetCXPageList/', data)
}

// 获取导出数据
export function exportSHXSSearchExcel(data) {
  return excel('py/SY_PY_SHXS_XSSQ/ExportExcel/', data)
}

// 获取导出数据
export function exportSHXSXYSearchExcel(data) {
  return excel('py/SY_PY_SHXS_XSSQ/ExportXYExcel/', data)
}

// 获取导出数据
export function exportSHXSXGSearchExcel(data) {
  return excel('py/SY_PY_SHXS_XSSQ/ExportXGExcel/', data)
}

// 获取优秀学生干部管理流程
export function GetXSGBStep() {
  return get('py/SY_PY_XSGB_LCPZ/GetStep/')
}

// 提交优秀学生干部流程配置
export function XSGBLCPZSave(data) {
  return post('py/SY_PY_XSGB_LCPZ/LCPZSave/', data)
}

// 保存批次设置
export function saveXSGBPCSZ(data) {
  return post('py/SY_PY_XSGB_PCSZ/Save/', data)
}

// 获取批次
export function GetXSGBPCPageList(data) {
  return get('py/SY_PY_XSGB_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getXSGBFormData(data) {
  return get('py/SY_PY_XSGB_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changeXSGBState(data) {
  return post('py/SY_PY_XSGB_PCSZ/SaveDQPC/', data)
}

// 学生当前批次
export function stuXSGBGetPC() {
  return get('py/SY_PY_XSGB_XSSQ/StuGetPC/')
}

// 学生获取申请分页数据
export function getXSGBStuApplyPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetStuApplyPageList/', data)
}

// 提交优秀学生干部流程配置
export function XSGBSave(data) {
  return post('py/SY_PY_XSGB_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getXSGBSQFormData(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getXSGBDSPPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetDSPPageList/', data)
}

// 获取待审批列表
export function getXSGBDXYSPPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetDXYSPPageList/', data)
}

// 获取待审批列表
export function getXSGBDXGSPPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetDXGSPPageList/', data)
}

// 提交审批
export function saveXSGBSP(data) {
  return post('py/SY_PY_XSGB_XSSQ/saveSP/', data)
}

// 提交审批
export function saveXSGBXYSP(data) {
  return post('py/SY_PY_XSGB_XSSQ/saveXYSP/', data)
}

// 提交审批
export function saveXSGBXGSP(data) {
  return post('py/SY_PY_XSGB_XSSQ/saveXGSP/', data)
}

// 获取PDF
export function getXSGBSQBPdf(data, filename) {
  return download('py/SY_PY_XSGB_XSSQ/GetSQBPdf/', data, filename)
}

// 获取PDF
export function getXSGBPdf(data, filename) {
  return download('py/SY_PY_XSGB_XSSQ/GetPdf/', data, filename)
}

// 获取PDF
export function getXSGBPdfUrl(data) {
  return post('py/SY_PY_XSGB_XSSQ/GetPdfUrl/', data)
}

// 获取查询列表
export function getXSGBCXPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetCXPageList/', data)
}

// 获取导出数据
export function exportXSGBSearchExcel(data) {
  return excel('py/SY_PY_XSGB_XSSQ/ExportExcel/', data)
}

// 获取导出数据
export function exportXSGBXYSearchExcel(data) {
  return excel('py/SY_PY_XSGB_XSSQ/ExportXYExcel/', data)
}
// 获取导出数据
export function exportXSGBXGSearchExcel(data) {
  return excel('py/SY_PY_XSGB_XSSQ/ExportXGExcel/', data)
}
// =============================================================
// 获取优秀毕业生流程
export function GetXYXXSStep() {
  return get('py/SY_PY_XYXXS_LCPZ/GetStep/')
}

// 提交优秀毕业生流程配置
export function XYXXSLCPZSave(data) {
  return post('py/SY_PY_XYXXS_LCPZ/LCPZSave/', data)
}

// 获取校优秀毕业生批次
export function GetXYXXSPCPageList(data) {
  return get('py/SY_PY_XYXXS_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getXYXXSFormData(data) {
  return get('py/SY_PY_XYXXS_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changeXYXXSState(data) {
  return post('py/SY_PY_XYXXS_PCSZ/SaveDQPC/', data)
}

// 保存批次设置
export function saveXYXXSPCSZ(data) {
  return post('py/SY_PY_XYXXS_PCSZ/Save/', data)
}

// 学生当前批次
export function stuXYXXSGetPC() {
  return get('py/SY_PY_XYXXS_XSSQ/StuGetPC/')
}

// 学生获取申请分页数据
export function getXYXXSStuApplyPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetStuApplyPageList/', data)
}

// 提交学生申请
export function XYXXSSave(data) {
  return post('py/SY_PY_XYXXS_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getXYXXSSQFormData(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getXYXXSDSPPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetDSPPageList/', data)
}

// 获取待审批列表
export function getXYXXSDXYSPPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetDXYSPPageList/', data)
}

// 获取待审批列表
export function getXYXXSDXGSPPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetDXGSPPageList/', data)
}

// 提交审批
export function saveXYXXSSP(data) {
  return post('py/SY_PY_XYXXS_XSSQ/saveSP/', data)
}

// 提交审批
export function saveXYXXSXYSP(data) {
  return post('py/SY_PY_XYXXS_XSSQ/saveXYPLSP/', data)
}

// 提交审批
export function saveXYXXSXGSP(data) {
  return post('py/SY_PY_XYXXS_XSSQ/saveXGPLSP/', data)
}

// 获取PDF
export function getXYXXSSQBPdf(data, filename) {
  return download('py/SY_PY_XYXXS_XSSQ/GetSQBPdf/', data, filename)
}

// 获取PDF
export function getXYXXSPdf(data, filename) {
  return download('py/SY_PY_XYXXS_XSSQ/GetPdf/', data, filename)
}

// 获取PDF
export function getXYXXSPdfUrl(data) {
  return post('py/SY_PY_XYXXS_XSSQ/GetPdfUrl/', data)
}

// 获取查询列表
export function getXYXXSCXPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetCXPageList/', data)
}

// 获取导出数据
export function exportXYXXSSearchExcel(data) {
  return excel('py/SY_PY_XYXXS_XSSQ/ExportExcel/', data)
}
// ====================================================================
// 获取优秀毕业生流程
export function GetPYXXSStep() {
  return get('py/SY_PY_PYXXS_LCPZ/GetStep/')
}

// 提交优秀毕业生流程配置
export function PYXXSLCPZSave(data) {
  return post('py/SY_PY_PYXXS_LCPZ/LCPZSave/', data)
}

// 获取省优秀毕业生批次
export function GetPYXXSPCPageList(data) {
  return get('py/SY_PY_PYXXS_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getPYXXSFormData(data) {
  return get('py/SY_PY_PYXXS_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changePYXXSState(data) {
  return post('py/SY_PY_PYXXS_PCSZ/SaveDQPC/', data)
}

// 保存批次设置
export function savePYXXSPCSZ(data) {
  return post('py/SY_PY_PYXXS_PCSZ/Save/', data)
}

// 学生当前批次
export function stuPYXXSGetPC() {
  return get('py/SY_PY_PYXXS_XSSQ/StuGetPC/')
}

// 学生获取申请分页数据
export function getPYXXSStuApplyPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetStuApplyPageList/', data)
}

// 提交学生申请
export function PYXXSSave(data) {
  return post('py/SY_PY_PYXXS_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getPYXXSSQFormData(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getPYXXSDSPPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetDSPPageList/', data)
}

// 提交审批
export function savePYXXSSP(data) {
  return post('py/SY_PY_PYXXS_XSSQ/saveSP/', data)
}

// 获取PDF
export function getPYXXSPdf(data, filename) {
  return download('py/SY_PY_PYXXS_XSSQ/GetPdf/', data, filename)
}

// 获取PDF
export function getPdfUrl(data) {
  return post('py/SY_PY_PYXXS_XSSQ/GetPdfUrl/', data)
}

// 获取PDF
export function getPYXXSSQBPdf(data, filename) {
  return download('py/SY_PY_PYXXS_XSSQ/GetSQBPdf/', data, filename)
}

// 获取查询列表
export function getPYXXSCXPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetCXPageList/', data)
}

// 获取导出数据
export function exportPYXXSSearchExcel(data) {
  return excel('py/SY_PY_PYXXS_XSSQ/ExportExcel/', data)
}

// 获取待审批列表
export function getPYXXSDXYSPPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetDXYSPPageList/', data)
}

// 提交审批
export function savePYXXSXYSP(data) {
  return post('py/SY_PY_PYXXS_XSSQ/saveXYPLSP/', data)
}

// 获取待审批列表
export function getPYXXSDXGSPPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetDXGSPPageList/', data)
}

// 提交审批
export function savePYXXSXGSP(data) {
  return post('py/SY_PY_PYXXS_XSSQ/saveXGPLSP/', data)
}

// 获取校优秀学生名单
export function getXYXXSMDPageList(data) {
  return get('py/SY_PY_XYXXS_XSSQ/GetMDCXPageList/', data)
}

// 删除名单
export function deleteXMD(data) {
  return post('py/SY_PY_XYXXS_XSSQ/deleteMD/', data)
}

// 获取校优秀学生名单
export function getPYXXSMDPageList(data) {
  return get('py/SY_PY_PYXXS_XSSQ/GetMDCXPageList/', data)
}

// 删除名单
export function deletePMD(data) {
  return post('py/SY_PY_PYXXS_XSSQ/deleteMD/', data)
}

// 单独审批
export function saveXGDXSP(data) {
  return post('py/SY_PY_PYXXS_XSSQ/saveXGDXSP/', data)
}

// 单独审批
export function saveXXGDXSP(data) {
  return post('py/SY_PY_XYXXS_XSSQ/saveXGDXSP/', data)
}

// 获取单项积极分子名单
export function getDXMDPageList(data) {
  return get('py/SY_PY_DX_XSSQ/GetMDCXPageList/', data)
}

// 删除单项积极分子名单
export function deleteDXMD(data) {
  return post('py/SY_PY_DX_XSSQ/deleteMD/', data)
}

// 获取三好学生名单
export function getSHXSMDPageList(data) {
  return get('py/SY_PY_SHXS_XSSQ/GetMDCXPageList/', data)
}

// 删除三好学生名单
export function deleteSHXSMD(data) {
  return post('py/SY_PY_SHXS_XSSQ/deleteMD/', data)
}

// 获取学生干部名单
export function getXSGBMDPageList(data) {
  return get('py/SY_PY_XSGB_XSSQ/GetMDCXPageList/', data)
}

// 删除学生干部名单
export function deleteXSGBMD(data) {
  return post('py/SY_PY_XSGB_XSSQ/deleteMD/', data)
}
